import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { confirmDialog } from 'primereact/confirmdialog';
import { useParams } from 'react-router-dom';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import 'primeicons/primeicons.css';

const EventsComments = () => {
  const [commentsData, setCommentsData] = useState([]);
  const [title, setTitle] = useState('');
  let { id } = useParams();

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };

    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date);

    return formattedDate;
  }

  const getComments = async () => {
    try {
      const response = await Apis.getEventComments(id);
      if (response.status === 'success') {
        setCommentsData(response.data);
        if (response.data.length > 0) {
          setTitle(response.data[0].title);
        }
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };

  useEffect(() => {
    getComments();
  }, []);

  const renderTable = () => {
    const columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.id}</div>
        ),
      },
      {
        id: 'comment',
        header: 'نص التعليق',
        accessorKey: 'comment',
        Cell: ({ row }) => {
          const [showMore, setShowMore] = useState(false);

          const toggleShowMore = () => {
            setShowMore(!showMore);
          };

          return (
            <div className="text-end fw-bolder">
              <div
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: showMore ? 'unset' : 2,
                  lineHeight: '1.5em',
                }}
              >
                {row.original.comment}
              </div>
              {row.original.comment.length > 100 && (
                <a
                  onClick={toggleShowMore}
                  style={{
                    color: '#3A7BBF',
                    cursor: 'pointer',
                  }}
                >
                  {showMore ? 'تصغير' : 'عرض المزيد'}
                </a>
              )}
            </div>
          );
        },
      },
      {
        id: 'user',
        header: 'المستخدم',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">
            <Link to={`/users/${row.original.user}`}>{row.original.name}</Link>
          </div>
        ),
      },
      {
        id: 'dateadded',
        header: 'تاريخ الاضافة',
        accessorKey: 'dateadded',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">
            <p style={{
              direction: "ltr"
            }}>
              {formatDate(row.original.dateadded)}
            </p>
          </div>
        ),
      },
      {
        id: 'delete',
        header: '',
        Cell: ({ row }) => {
          const accept = async () => {
            const response = await Apis.deleteEventComment(row.original.id);
            if (response.status === 'success') {
              toast.success('تم حذف التعليق بنجاح');
              getComments();
            } else {
              toast.error('حدث خطأ, لم يتم حذف التعليق');
            }
          };

          const reject = () => {};

          return (
            <Button variant='danger' onClick={() => {
              confirmDialog({
                message: 'هل أنت متأكد انك تريد حذف هذا التعليق؟',
                header: 'تأكيد الحذف',
                acceptClassName: 'p-button-danger',
                rejectLabel: "لا",
                acceptLabel: "نعم",
                accept,
                reject
              });
            }}>
              حذف
            </Button>
          );
        },
      },
    ];

    return (
      <MaterialReactTable
        data={commentsData}
        columns={columns}
        options={{
          search: false,
          paging: false,
        }}
      />
    );
  };

  const renderCard = () => {
    return (
      <div className="card-deck">
        {commentsData.map((commentItem) => (
          <Card key={commentItem.id} style={{ marginBottom: '20px' }}>
            <Card.Body>
              <Card.Title> <Link to={`/users/${commentItem.user}`} style={{
                textDecoration:"none"
              }}>
                {commentItem.name}
              </Link></Card.Title>
              <Card.Text>{commentItem.comment}</Card.Text>
              <Card.Text style={{
                direction: 'ltr',
                textAlign:"right"
              }}>{formatDate(commentItem.dateadded)}</Card.Text>
             
              <Button
                variant="danger"
                onClick={() => {
                  const accept = async () => {
                    const response = await Apis.deleteEventComment(commentItem.id);
                    if (response.status === 'success') {
                      toast.success('تم حذف التعليق بنجاح');
                      getComments();
                    } else {
                      toast.error('حدث خطأ, لم يتم حذف التعليق');
                    }
                  };
        
                  const reject = () => {};
        
                  confirmDialog({
                    message: 'هل أنت متأكد انك تريد حذف هذا التعليق؟',
                    header: 'تأكيد الحذف',
                    acceptClassName: 'p-button-danger',
                    rejectLabel: "لا",
                    acceptLabel: "نعم",
                    accept,
                    reject
                  });
                }}
              >
                حذف
              </Button>
            </Card.Body>
          </Card>
        ))}
      </div>
    );
  };

  const handleDelete = async (id) => {
    const response = await Apis.deleteEventComment(id);
    if (response.status === 'success') {
      toast.success('تم حذف التعليق بنجاح');
      getComments();
    } else {
      toast.error('حدث خطأ, لم يتم حذف التعليق');
    }
  };

  return (
    <div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h2>التعليقات على الفعالية : {title}</h2>
        </div>

        {/* Render either table or card layout based on screen size */}
        { renderTable()}

      </div>
    </div>
  );
};

export default EventsComments;
