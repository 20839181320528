import * as React from 'react';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import { useState,useEffect } from 'react';
import Apis from './Apis';
export default function ServiceRating({id}) {
    const [value,setValue]=useState(0)
    const getServiceRating = async () => {
        try {
          const response = await Apis.getServiceRating(id);
          if (response.status === 'success') {
            let sum=0
            

            response.data.forEach(rating => {
                sum+=rating.rating
            });
            setValue(Math.round(parseFloat(sum/response.data.length)*10)/10 );
          }
        } catch (error) {
          console.error('Error fetching services:', error);
        }
      };
    
      useEffect(() => {
        getServiceRating();
      }, []);
      useEffect(()=>{

        console.log(value)
      },[value])
  return (
    <Stack spacing={1} style={{
        direction:"ltr"
    }}>
      <Rating name="half-rating-read" value={value} precision={0.1} readOnly />
    </Stack>
  );
}