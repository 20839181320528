import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'primeicons/primeicons.css';

const InfoCenter = () => {
  const [infoCentersData, setInfoCentersData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addImageURL, setAddImageUrl] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [position, setPosition] = useState('');
  const [doneby, setDoneby] = useState('');
  const [textBody, setTextBody] = useState('');
  const [startdate, setstartdate] = useState(new Date());
  const [enddate, setenddate] = useState(new Date());

  const onTemplateUpload = (e) => {
    setAddImageUrl(JSON.parse(e.xhr.response).url);
  };

  const getInfoCenters = async () => {
    try {
      const response = await Apis.getInfoCenters();
      if (response.status === 'success') {
        console.log(response.data)
        setInfoCentersData(response.data);
      }
    } catch (error) {
      console.error('Error fetching infoCenters:', error);
    }
  };
  const formatDateTime = (dateString) => {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
    return formattedDate;
  };
  const formatDate = (dateString) => {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()}`;
    return formattedDate;
  };
  useEffect(() => {
    getInfoCenters();
  }, []);

  const renderTable = () => {
    const columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.id}</div>
        ),
      },
      {
        id: 'name',
        header: 'الاسم',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.name}</div>
        ),
      },
      {
        id: 'email',
        header: 'البريد الالكتروني',
        accessorKey: 'email',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.email}</div>
        ),
      },
      {
        id: 'mobile',
        header: 'رقم الهاتف',
        accessorKey: 'mobile',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.mobile}</div>
        ),
      },
      {
        id: 'position',
        header: 'المسمى الوظيفي',
        accessorKey: 'position',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.position}</div>
        ),
      },
     
      {
        id: 'delete',
        header: '',
        Cell: ({ row }) => {
          const accept = async () => {
            const response = await Apis.deleteInfoCenter(row.original.id);
            if (response.status === 'success') {
              toast.success('تم حذف المعلومة بنجاح');
              getInfoCenters();
            } else {
              toast.error('حدث خطأ, لم يتم حذف المعلومة');
            }
          };

          const reject = () => {};

          return (
            <Button
              variant="danger"
              onClick={() => {
                confirmDialog({
                  message: 'هل أنت متأكد انك تريد حذف هذا المعلومة؟',
                  header: 'تأكيد الحذف',
                  acceptClassName: 'p-button-danger',
                  rejectLabel: 'لا',
                  acceptLabel: 'نعم',
                  accept,
                  reject,
                });
              }}
            >
              حذف
            </Button>
          );
        },
      },
     /* {
        id: 'comments',
        header: '',
        Cell: ({ row }) => (
          <Button variant="primary">
            <Link
              to={`/infoCenterscomments/${row.original.id}`}
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
            >
              التعليقات
            </Link>
          </Button>
        ),
      },*/
    ];

    return (
      <MaterialReactTable
        data={infoCentersData}
        columns={columns}
        options={{
          search: false,
          paging: false,
        }}
      />
    );
  };

  const renderCard = () => {
    return (
      <div className="card-deck">
        {infoCentersData.map((infoCenterItem) => (
          <Card key={infoCenterItem.id} style={{ marginBottom: '20px' }}>
            <Card.Img variant="top" src={infoCenterItem.image} />
            <Card.Body>
              <Card.Title>{infoCenterItem.title}</Card.Title>
              <Card.Text>{infoCenterItem.bodytext}</Card.Text>
              <Card.Text>{formatDateTime(infoCenterItem.startdate)}</Card.Text>
             {/* <Link to={`/infoCenterscomments/${infoCenterItem.id}`} className="btn btn-primary">
                التعليقات
        </Link>*/}
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">
                <Button
                  variant="danger"
                  onClick={() => {
                    const accept = async () => {
                      const response = await Apis.deleteInfoCenter(infoCenterItem.id);
                      if (response.status === 'success') {
                        toast.success('تم حذف المعلومة بنجاح');
                        getInfoCenters();
                      } else {
                        toast.error('حدث خطأ, لم يتم حذف المعلومة');
                      }
                    };
          
                    const reject = () => {};
                    confirmDialog({
                      message: 'هل أنت متأكد انك تريد حذف هذه المعلومة؟',
                      header: 'تأكيد الحذف',
                      acceptClassName: 'p-button-danger',
                      rejectLabel: 'لا',
                      acceptLabel: 'نعم',
                      accept,
                      reject,
                    });
                  
                  }}
                >
                  حذف
                </Button>
              </small>
            </Card.Footer>
          </Card>
        ))}
      </div>
    );
  };

  const handleDelete = async (id) => {
    const response = await Apis.deleteInfoCenter(id);
    if (response.status === 'success') {
      toast.success('تم حذف المعلومة بنجاح');
      getInfoCenters();
    } else {
      toast.error('حدث خطأ, لم يتم حذف المعلومة');
    }
  };

  return (
    <div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>المعلومات</h1>

          <div>
            <Button onClick={() => setVisible(true)}>إضافة معلومة جديدة</Button>
          </div>
        </div>

        {/* Render either table or card layout based on screen size */}
        { renderTable()}

        <Dialog
          header="إضافة معلومة"
          visible={visible}
          style={{ width: '90vw', direction: 'rtl' }}
          onHide={() => setVisible(false)}
        >
       

    
<p style={{ fontWeight: 'bold', marginTop: 20 }}>الاسم</p>
          <InputText
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ width: '100%' }}
          />

<p style={{ fontWeight: 'bold', marginTop: 20 }}>البريد الالكتروني</p>
          <InputText
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: '100%' }}
          />

<p style={{ fontWeight: 'bold', marginTop: 20 }}>رقم الهاتف</p>
          <InputTextarea
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            style={{ width: '100%' }}
          />
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>المسمى الوظيفي</p>
          <InputTextarea
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            style={{ width: '100%' }}
          />

          <Button
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
            }}
            onClick={async () => {
              console.log(startdate.toLocaleString())
              const response = await Apis.addInfoCenter(name,email,mobile,position);
              console.log(response)
              if (response.status === 'success') {
                toast.success('تم إضافة المعلومة بنجاح');
                getInfoCenters();
                setVisible(false);
              } else {
                toast.error('حدث خطأ, لم يتم إضافة المعلومة');
                getInfoCenters();
                setVisible(false);
              }
            }}
          >
            إضافة
          </Button>
        </Dialog>
      </div>
    </div>
  );
};

export default InfoCenter;
