import {  toast } from 'react-toastify';
//const url = config.apiUrl;
//const url = "https://municipalities.bombasticstore.net/api/";
const url = "https://api.ask4you.app/api/";
//const url = "http://192.168.1.115:4000/api/";



export default {
 
  UpdatePassword: async (currentPassword,newPassword) => {
    return fetch(url + "updatePassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        currentPassword,newPassword
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  
  adminLogin: async (username, password) => {
    return fetch(url + "adminLogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        username, password
            }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
    
  adminLoginSuper: async (id) => {
    return fetch(url + "adminLoginSuper", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("superadmintoken")

      },
      body: JSON.stringify({
        id
            }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getNews: async () => {
    return fetch(url + "getNews", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getVolunteer: async () => {
    return fetch(url + "getVolunteerData", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getComplaints: async () => {
    return fetch(url + "getComplaints", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getLaw: async () => {
    return fetch(url + "getLaw", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getUsers: async () => {
    return fetch(url + "getUsers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getFaq: async () => {
    return fetch(url + "getFaq", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getVolunteers: async (id) => {
    return fetch(url + "getVolunteers?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteNews: async (id) => {
    return fetch(url + "deleteNews?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteLaw: async (id) => {
    return fetch(url + "deleteLaw?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteFaq: async (id) => {
    return fetch(url + "deleteFaq?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteVolunteerData: async (id) => {
    return fetch(url + "deleteVolunteerData?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addNews: async (title,body,image) => {
    return fetch(url + "addNews", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,body,image
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addadmin: async (name,username,password,email,mobile,municipality) => {
    return fetch(url + "addadmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("superadmintoken")

      },
      body: JSON.stringify({
        name,username,password,email,mobile,municipality
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addLaw: async (title,body) => {
    return fetch(url + "addLaw", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,body
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addFaq: async (title,body) => {
    return fetch(url + "addFaq", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,body
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addVolunteerData: async ( title,
    description,
    field,
     addImageURL) => {
    return fetch(url + "addVolunteerData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,
               description,
               field,
                addImageURL
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getNewsComments: async (id) => {
    return fetch(url + "getNewsComments?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteNewsComment: async (id) => {
    return fetch(url + "deleteNewsComment?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getEvents: async () => {
    return fetch(url + "getEvents", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getProjects: async () => {
    return fetch(url + "getProjects", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getInfoCenters: async () => {
    return fetch(url + "getInfoCenter", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getInfos: async () => {
    return fetch(url + "getInfos", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getMunicipalities: async () => {
    return fetch(url + "getMunicipalities", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getAdmins: async (id) => {
    return fetch(url + "getAdmins?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("superadmintoken")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteEvent: async (id) => {
    return fetch(url + "deleteEvent?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteProject: async (id) => {
    return fetch(url + "deleteProject?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteInfoCenter: async (id) => {
    return fetch(url + "deleteInfoCenter?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteInfo: async (id) => {
    return fetch(url + "deleteInfo?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteadmin: async (id) => {
    return fetch(url + "deleteadmin?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("superadmintoken")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteMunicipality: async (id) => {
    return fetch(url + "deleteMunicipality?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("superadmintoken")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addEvent: async (title,person,mobile,body,image,dateandtime) => {
    return fetch(url + "addEvent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,person,mobile,body,image,dateandtime
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addInfoFile: async (image,id) => {
    return fetch(url + "addInfoFile", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
       image,id
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addProject: async (title,body,image,startdate,enddate,doneby,location,supervisor,target,sponsor,budget) => {
    return fetch(url + "addProject", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,body,image,startdate,enddate,doneby,location,supervisor,target,sponsor,budget
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addInfoCenter: async (name,email,mobile,position) => {
    return fetch(url + "addInfoCenter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        name,email,mobile,position
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addInfo: async (title,body,image) => {
    return fetch(url + "addInfo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,body,image
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addMunicipality: async (title,image) => {
    return fetch(url + "addMunicipality", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("superadmintoken")

      },
      body: JSON.stringify({
        title,image
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  editMunicipality: async (id,title,image) => {
    return fetch(url + "editMunicipality", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("superadmintoken")

      },
      body: JSON.stringify({
        id,title,image
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getEventComments: async (id) => {
    return fetch(url + "getEventComments?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getInfoFiles: async (id) => {
    return fetch(url + "getInfoFiles?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteEventComment: async (id) => {
    return fetch(url + "deleteEventComment?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteInfoFile: async (id) => {
    return fetch(url + "deleteInfoFile?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPolls: async () => {
    return fetch(url + "getPolls", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPoll: async (id) => {
    return fetch(url + "getPoll?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPollStats: async (id) => {
    return fetch(url + "getPollStats?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addPoll: async (title,body) => {
    return fetch(url + "addPoll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,body
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deletePoll: async (id) => {
    return fetch(url + "deletePoll?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addQuestionToPoll: async (id,question,questiontype) => {
    return fetch(url + "addQuestionToPoll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        id,question,questiontype
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPollQuestions: async (id) => {
    return fetch(url + "getPollQuestions?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPollQuestionOptions: async (id) => {
    return fetch(url + "getPollQuestionOptions?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  updatePollQuestionOption : async (id,newOption) => {
    return fetch(url + "updatePollQuestionOption?id="+id+"&newoption="+newOption, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  updatePollQuestion : async (id,newquestion,questiontype) => {
    return fetch(url + "updatePollQuestion?id="+id+"&newquestion="+newquestion+"&questiontype="+questiontype, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addOptionToPollQuestion: async (id,option) => {
    return fetch(url + "addOptionToPollQuestion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        id,option
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deletePollQuestion: async (id) => {
    return fetch(url + "deletePollQuestion?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deletePollQuestionOption: async (id) => {
    return fetch(url + "deletePollQuestionOption?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  
getServices: async () => {
  return fetch(url + "getServices", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
getService: async (id) => {
  return fetch(url + "getService?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
getServiceRating: async (id) => {
  return fetch(url + "getServiceRating?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
getServiceStats: async (id) => {
  return fetch(url + "getServiceStats?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
addService: async (title,body) => {
  return fetch(url + "addService", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
    body: JSON.stringify({
      title,body
    }),
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
        )
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
deleteService: async (id) => {
  return fetch(url + "deleteService?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
addQuestionToService: async (id,question) => {
  return fetch(url + "addQuestionToService", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
    body: JSON.stringify({
      id,question
    }),
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
        )
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
getServiceQuestions: async (id) => {
  return fetch(url + "getServiceQuestions?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
getServiceQuestionOptions: async (id) => {
  return fetch(url + "getServiceQuestionOptions?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
addOptionToServiceQuestion: async (id,option) => {
  return fetch(url + "addOptionToServiceQuestion", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
    body: JSON.stringify({
      id,option
    }),
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
        )
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
deleteServiceQuestion: async (id) => {
  return fetch(url + "deleteServiceQuestion?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
deleteServiceQuestionOption: async (id) => {
  return fetch(url + "deleteServiceQuestionOption?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
};
