import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import 'primeicons/primeicons.css';

const Volunteers = () => {
  const [volunteerData, setVolunteerData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addImageURL, setAddImageUrl] = useState('');
  const [title, setTitle] = useState('');
const [dateandtime,setDateAndTime]=useState(dayjs(new Date(Date.now())))
let { id } = useParams();

  const getVolunteer = async () => {
    try {
      const response = await Apis.getVolunteers(id);
      if (response.status === 'success') {
        console.log(response)
        setVolunteerData(response.data);
      }
    } catch (error) {
      console.error('Error fetching volunteer:', error);
    }
  };

  useEffect(() => {
    getVolunteer();
  }, []);
  function formatDate(dateString) {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()}`;
    return formattedDate;
  }

  const onTemplateUpload = (e) => {
    setAddImageUrl(JSON.parse(e.xhr.response).url);
  };
  const formatDateTime = (dateString) => {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
    return formattedDate;
  };
  const renderTable = () => {
    const columns = [
      {
        id: 'volunteerid',
        header: '#',
        accessorKey: 'volunteerid',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.volunteerid}</div>
        ),
      },
      {
        id: 'name',
        header: 'الاسم',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.name}</div>
        ),
      },
      {
        id: 'mobile',
        header: 'رقم الهاتف',
        accessorKey: 'mobile',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.mobile}</div>
        ),
      },
      {
        id: 'email',
        header: 'البريد الالكتروني',
        accessorKey: 'email',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.email}</div>
        ),
      },
      {
        id: 'job',
        header: 'العمل',
        accessorKey: 'job',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.job}</div>
        ),
      },
      {
        id: 'dob',
        header: 'تاريخ الميلاد',
        accessorKey: 'dob',
        Cell: ({ row }) => (
       
          <div className="text-end fw-bolder">{  formatDate(row.original.dob)}</div>

    
    
    
        ),
      },
      {
        id: 'gender',
        header: 'الجنس',
        accessorKey: 'gender',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.gender}</div>
        ),
      },
      {
        id: 'disability',
        header: 'احتياجات خاصة؟',
        accessorKey: 'disability',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.disability==0?"لا":"نعم"}</div>
        ),
      },
      {
        id: 'availability',
        header: 'الاتاحة',
        accessorKey: 'availability',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.availability}</div>
        ),
      },
      {
        id: 'skills',
        header: 'المهارات',
        accessorKey: 'skills',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.skills}</div>
        ),
      },
      {
        id: 'interests',
        header: 'الاهتمامات',
        accessorKey: 'interests',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.interests}</div>
        ),
      },
      {
        id: 'field',
        header: 'مجال التطوع',
        accessorKey: 'field',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.field}</div>
        ),
      },
     
    ];

    return (

      <div style={{
        width:window.innerWidth-300
      }}>
<MaterialReactTable
        data={volunteerData}
        columns={columns}
        options={{
          search: false,
          paging: false,
        }}
      />

      </div>
      
    );
  };

  const renderCard = () => {
    return (
      <div className="card-deck">
        {volunteerData.map((volunteerItem) => (
          <Card key={volunteerItem.id} style={{ marginBottom: '20px' }}>
           
            <Card.Body>
              <Card.Title>{volunteerItem.name}</Card.Title>
              <Card.Text>رقم الهاتف: { volunteerItem.mobile}</Card.Text>
              <Card.Text>البريد الالكتروني: { volunteerItem.email}</Card.Text>
              <Card.Text>طبيعة العمل: { volunteerItem.job}</Card.Text>
              <Card.Text>تاريخ الميلاد: {formatDate(volunteerItem.dob)}</Card.Text>
              <Card.Text>الجنس: { volunteerItem.gender}</Card.Text>
              <Card.Text>احتياجات خاصة؟ { volunteerItem.disability?"نعم":"لا"}</Card.Text>

            </Card.Body>
          
          </Card>
        ))}
      </div>
    );
  };

  const handleDelete = async (id) => {
    const response = await Apis.deleteVolunteerData(id);
    if (response.status === 'success') {
      toast.success('تم حذف الفعالية بنجاح');
      getVolunteer();
    } else {
      toast.error('حدث خطأ, لم يتم حذف الفعالية');
    }
  };

  return (


    <LocalizationProvider dateAdapter={AdapterDayjs}>

<div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>المتطوعين </h1>
  
       
        </div>
  
        {/* Render either table or card layout based on screen size */}
        { renderTable()}
  
        <Dialog
          header="إضافة فعالية"
          visible={visible}
          style={{ width: '90vw', direction: 'rtl' }}
          onHide={() => setVisible(false)}
        >
          {addImageURL && (
            <img
              src={addImageURL}
              style={{
                width: 200,
                height: 200,
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                marginBottom: 20,
                borderRadius: 20,
              }}
              alt="Added Image"
            />
          )}
  
          <FileUpload
            style={{
              direction: 'ltr',
              justifyContent: 'center',
              display: 'flex',
            }}
            mode="basic"
            name="file"
            url="https://api.ask4you.app/api/upload"
            accept="image/*"
            maxFileSize={1000000000}
            onUpload={onTemplateUpload}
            auto
            chooseLabel="صورة الفعالية"
          />
  
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>عنوان الفعالية</p>
          <InputText
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '100%' }}
          />
  
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>تاريخ الفعالية</p>
          <div style={{
            width:"100%"
          }}> 

                      <DateTimePicker label="" ampmInClock  value={dateandtime}
  onChange={(newValue) => setDateAndTime(newValue)}/>

          </div>

  
          <Button
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              marginTop:20
            }}
            onClick={async () => {
              const response = await Apis.addVolunteerData(
                title,
                dateandtime.toLocaleString(),
                addImageURL
              );
              if (response.status === 'success') {
                toast.success('تم إضافة الفعالية بنجاح');
                getVolunteer();
                setVisible(false);
              } else {
                toast.error('حدث خطأ, لم يتم إضافة الفعالية');
                getVolunteer();
                setVisible(false);
              }
            }}
          >
            إضافة
          </Button>
        </Dialog>
      </div>
    </div>


  </LocalizationProvider>


    
  );
  
};

export default Volunteers;
