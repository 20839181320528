import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'primeicons/primeicons.css';

const Users = () => {
  const [usersData, setUsersData] = useState([]);


  const getUsers = async () => {
    try {
      const response = await Apis.getUsers();
      if (response.status === 'success') {
        console.log(response.data)
        setUsersData(response.data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };
  function formatDate(dateString) {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()}`;
    return formattedDate;
  }
  const formatDateTime = (dateString) => {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
    return formattedDate;
  };
  useEffect(() => {
    getUsers();
  }, []);

  const renderTable = () => {
    const columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.id}</div>
        ),
      },
      {
        id: 'name',
        header: 'الاسم',
        accessorKey: 'name',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.name}</div>
        ),
      },
      {
        id: 'mobile',
        header: 'رقم الهاتف',
        accessorKey: 'mobile',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.mobile}</div>
        ),
      },
      {
        id: 'email',
        header: 'البريد الالكتروني',
        accessorKey: 'email',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.email}</div>
        ),
      },
      {
        id: 'job',
        header: 'العمل',
        accessorKey: 'job',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.job}</div>
        ),
      },
      {
        id: 'dob',
        header: 'تاريخ الميلاد',
        accessorKey: 'dob',
        Cell: ({ row }) => (
       
          <div className="text-end fw-bolder">{  formatDate(row.original.dob)}</div>

    
    
    
        ),
      },
      {
        id: 'gender',
        header: 'الجنس',
        accessorKey: 'gender',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.gender}</div>
        ),
      },
      {
        id: 'disability',
        header: 'احتياجات خاصة؟',
        accessorKey: 'disability',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.disability==0?"لا":"نعم"}</div>
        ),
      },
     
    ];

    return (
      <MaterialReactTable
        data={usersData}
        columns={columns}
        options={{
          search: false,
          paging: false,
        }}
      />
    );
  };

  const renderCard = () => {
    return (
      <div className="card-deck">
        {usersData.map((userItem) => (
          <Card key={userItem.id} style={{ marginBottom: '20px' }}>
           
            <Card.Body>
              <Card.Title>{userItem.name}</Card.Title>
              <Card.Text>رقم الهاتف: { userItem.mobile}</Card.Text>
              <Card.Text>البريد الالكتروني: { userItem.email}</Card.Text>
              <Card.Text>طبيعة العمل: { userItem.job}</Card.Text>
              <Card.Text>تاريخ الميلاد: {formatDate(userItem.dob)}</Card.Text>
              <Card.Text>الجنس: { userItem.gender}</Card.Text>
              <Card.Text>احتياجات خاصة؟ { userItem.disability?"نعم":"لا"}</Card.Text>

            </Card.Body>
          
          </Card>
        ))}
      </div>
    );
  };

  const handleDelete = async (id) => {
    const response = await Apis.deleteUser(id);
    if (response.status === 'success') {
      toast.success('تم حذف الفعالية بنجاح');
      getUsers();
    } else {
      toast.error('حدث خطأ, لم يتم حذف الفعالية');
    }
  };

  return (
    <div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>المستخدمين</h1>

       
        </div>

        {/* Render either table or card layout based on screen size */}
        { renderTable()}

       
      </div>
    </div>
  );
};

export default Users;
