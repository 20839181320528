import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import 'primeicons/primeicons.css';

const Complaints = () => {
  const [complaintData, setComplaintData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addImageURL, setAddImageUrl] = useState('');
  const [title, setTitle] = useState('');
const [dateandtime,setDateAndTime]=useState(dayjs(new Date(Date.now())))
  const getComplaint = async () => {
    try {
      const response = await Apis.getComplaints();
      if (response.status === 'success') {
        setComplaintData(response.data);
      }
    } catch (error) {
      console.error('Error fetching complaint:', error);
    }
  };

  useEffect(() => {
    getComplaint();
  }, []);

  const onTemplateUpload = (e) => {
    setAddImageUrl(JSON.parse(e.xhr.response).url);
  };
  const formatDateTime = (dateString) => {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
    return formattedDate;
  };
  const renderTable = () => {
    const columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.id}</div>
        ),
      },
      {
        id: 'complaint',
        header: 'الشكوى',
        accessorKey: 'complaint',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.complaint}</div>
        ),
      },
      {
        id: 'location',
        header: 'موقع المشكلة',
        accessorKey: 'location',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.location}</div>
        ),
      },
      {
        id: 'reason',
        header: 'السبب',
        accessorKey: 'reason',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.reason}</div>

        ),
      },
      {
        id: 'argent',
        header: 'مستعجل؟',
        accessorKey: 'argent',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.argent?"نعم":"لا"}</div>

        ),
      },
      {
        id: 'username',
        header: 'اسم المستخدم',
        accessorKey: 'username',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.username}</div>

        ),
      },
      {
        id: 'image',
        header: 'الصورة',
        accessorKey: 'image',
        Cell: ({ row }) => (
          <a href={row.original.image} target="_blank">
            <img
              src={row.original.image}
              alt={`Image ${row.original.id}`}
              style={{ width: 100, height: 100 }}
            />
          </a>
        ),
      },
     
    ];

    return (
      <MaterialReactTable
        data={complaintData}
        columns={columns}
        options={{
          search: false,
          paging: false,
        }}
      />
    );
  };



  const handleDelete = async (id) => {
    const response = await Apis.deleteComplaintData(id);
    if (response.status === 'success') {
      toast.success('تم حذف الفعالية بنجاح');
      getComplaint();
    } else {
      toast.error('حدث خطأ, لم يتم حذف الفعالية');
    }
  };

  return (


    <LocalizationProvider dateAdapter={AdapterDayjs}>

<div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>الشكاوى</h1>
  
        
        </div>
  
        {/* Render either table or card layout based on screen size */}
        {renderTable()}
  
     
      </div>
    </div>


  </LocalizationProvider>


    
  );
  
};

export default Complaints;
