import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Apis from './Apis';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
ChartJS.register(ArcElement, Tooltip, Legend);

const PollStats = () => {
  // Extract unique question IDs
  const [data, setData] = useState([]);
  let { id } = useParams();


  const getPollStats = async () => {
    try {
      const response = await Apis.getPollStats(id);
      if (response.status === 'success') {
        console.log(response.data)
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    getPollStats();
  }, []);
  let questionIds = Array.from(new Set(data.map((item) => { return({id:item.questionid,text:item.questiontext})})));
function removeDuplicateJSONs(jsonArray) {
    const uniqueJSONs = [];
    const seenJSONs = new Set();

    jsonArray.forEach(json => {
        const jsonString = JSON.stringify(json);
        if (!seenJSONs.has(jsonString)) {
            seenJSONs.add(jsonString);
            uniqueJSONs.push(json);
        }
    });

    return uniqueJSONs;
}
questionIds=removeDuplicateJSONs(questionIds)
console.log(questionIds)

  const getChartData = (questionId) => {
    // Filter data for the current question
    const questionData = data.filter((item) => item.questionid === questionId);

    // Count the occurrences of each option
    const optionCount = questionData.reduce((acc, item) => {
      const optionText = item.optiontext;
      acc[optionText] = (acc[optionText] || 0) + 1;
      return acc;
    }, {});

    // Create labels and data for the chart
    const labels = Object.keys(optionCount);
    const chartData = labels.map((label) => optionCount[label]);

    // Generate random colors for each option (you can customize this)
    const backgroundColor = labels.map(() => `#${Math.floor(Math.random() * 16777215).toString(16)}`);

    return {
      labels,
      datasets: [
        {
          data: chartData,
          backgroundColor,
        },
      ],
    };
  };

  return (
    <div dir='rtl'>

        <h2>
            احصائيات {
                data.length>0 &&(
                    data[0].polltitle
                )
            }
        </h2>
        <Row>

      {questionIds.map((questionId) => (
                      <Col xs={11} sm={11} md={6} lg={4} >
                         <div key={questionId.id} style={{
                        border:"1px solid #00000066",
                        borderRadius:"10px",
                        padding:"10px"
                      }}>
          <h3 style={{
            textAlign:'center',
          }}>{questionId.text}</h3>
          <Pie data={getChartData(questionId.id)} />
        </div>
</Col>
       
      ))}
              </Row>

    </div>
  );
};

export default PollStats;
