import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button, Col, Row } from 'react-bootstrap';
import Apis from './Apis';
import { isMobile } from 'react-device-detect';
// Assume Apis.getServiceQuestions, Apis.addQuestionToService, and Apis.getServiceQuestionOptions are defined somewhere in your code.

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ServiceQuestionOptions from './ServiceQuestionOptions';
import { confirmDialog } from 'primereact/confirmdialog';

const EditService = () => {
  const [questions, setQuestions] = useState([]);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [serviceTitle,setServiceTitle]=useState("")
  const [serviceDescription,setServiceDescription]=useState("")
  let { id } = useParams();

  useEffect(() => {
    // Fetch questions initially
    getQuestions();
    getService()
    getServiceStats()
  }, []);

  const getQuestions = async () => {
    const response = await Apis.getServiceQuestions(id);
    if (response.status === 'success') {
        console.log(response.data)
      setQuestions(response.data);
    }
  };

  const getServiceStats = async () => {
    const response = await Apis.getServiceStats(id);
    if (response.status === 'success') {
        console.log(response.data)
      
    }
  };
  const getService = async () => {

  const response = await Apis.getService(id);
  if (response.status === 'success') {
      console.log(response.data)
      if(response.data.length>0)
      {
          setServiceTitle(response.data[0].title);
      setServiceDescription(response.data[0].description);
  }
  }
};


  const handleAddQuestion = async () => {
    const response = await Apis.addQuestionToService(id,newQuestion);
    if (response.status === 'success') {
      // Reload questions
      getQuestions();
      setDialogVisible(false);
      setNewQuestion('');
    } else {
      toast.error('حدث خطأ، لم يتم إضافة السؤال');
    }
  };



  return (
    <div style={{
        direction:"rtl"
    }}>

<p>
    {
        "خدمة رقم : "+id
    }
</p>
<h3>
    {
        serviceTitle
    }
</h3>
<h4 style={{
    fontSize:"16px",
    marginBottom:"20px"
}}>
    {
        serviceDescription
    }
</h4>
{
    questions.map((question) => (
        <div key={question.id} style={{
            border:"1px solid #00000077",
            padding:"20px",
            borderRadius:"5px",
            marginBottom:"20px"
        }}>
            <div style={{
                display:"flex",
                flexDirection:"row"
            }}>
 <h4 style={{
    marginLeft:"30px"
 }}>
                {question.question}
                
            </h4>
            <a variant="danger" style={{
                fontSize:"16px",
                color:"red",
                cursor:"pointer",
                fontWeight:"bold"
            }} onClick={() => {
                const accept = async () => {
                  const response = await Apis.deleteServiceQuestion(question.id);
                  if (response.status === 'success') {
                    toast.success('تم حذف السؤال بنجاح');
                    getQuestions();
                  } else {
                    toast.error('حدث خطأ, لم يتم حذف السؤال');
                  }
                };
      
                const reject = () => {};
                confirmDialog({
                  message: 'هل أنت متأكد انك تريد حذف هذا السؤال؟',
                  header: 'تأكيد الحذف',
                  acceptClassName: 'p-button-danger',
                  rejectLabel: 'لا',
                  acceptLabel: 'نعم',
                  accept,
                  reject,
                });
              
              }  }>
       حذف 
      </a>
                </div>
           
           
            
          <ServiceQuestionOptions id={question.id}/>
        </div>
      ))
}
      <a style={{
        color:"#1976D2",
        fontWeight:"bold",
        cursor:"pointer"
      }} onClick={() => setDialogVisible(true)}>
        إضافة سؤال
      </a>

      <Dialog
        visible={isDialogVisible}
        style={{
            direction:"rtl",
            width:"90%"
        }}
        onHide={() => setDialogVisible(false)}
        header="إضافة سؤال"
        footer={
          <div>
             <Button variant="primary" onClick={handleAddQuestion}>
              اضافة
            </Button>
            <Button variant="secondary" style={{
                marginRight:"20px"
            }} onClick={() => setDialogVisible(false)}>
              الغاء
            </Button>
           
          </div>
        }
      >
        <label style={{
            width:isMobile?"20%":"10%"
        }}>السؤال: </label>
        <input type="text" style={{
            width:isMobile?"80%":"90%"
        }} value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)} />
      </Dialog>
    </div>
  );
};

export default EditService;
