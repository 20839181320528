import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import 'primeicons/primeicons.css';
import ServiceRating from './ServiceRating';

const Services = () => {
  const [servicesData, setServicesData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [textBody, setTextBody] = useState('');



  const getServices = async () => {
    try {
      const response = await Apis.getServices();
      if (response.status === 'success') {
        setServicesData(response.data);
      }
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

const Cardbody=({serviceItem})=>{

  const [showMore,setShowMore]=useState(false)
  return (
    <Card.Text>
    <div
      style={{
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: showMore?'unset': 2,
        lineHeight: '1.5em',
      }}
    >
    {serviceItem.description}
    </div>
    {serviceItem.description.length > 100 && (
                <a
                  onClick={()=>{
                    setShowMore(!showMore)
                  }}
                  style={{
                    color: '#3A7BBF',
                    cursor: 'pointer',
                  }}
                >
                  {showMore ? 'تصغير' : 'عرض المزيد'}
                </a>
              )}
              
    </Card.Text>

  )
}
  const renderCard = () => {
    return (
      <div className="card-deck">
         <Container>
            <Row>
        {servicesData.map((serviceItem) => (
          <> 
          
         
              <Col xs={12} sm={6} md={4} lg={4}>
              <Card key={serviceItem.id} style={{ marginBottom: '20px' }}>
            <Card.Body>
              <Card.Title>{serviceItem.id} : {serviceItem.title}</Card.Title>
              <ServiceRating id={serviceItem.id}/>
             <Cardbody serviceItem={serviceItem}/>
            <Link to={`/serviceedit/${serviceItem.id}`} className="btn btn-primary" style={{
              marginLeft:"20px",
              marginBottom:"10px"
             }}>
                تعديل اسئلة التقييم
              </Link>


              <Link to={`/servicestats/${serviceItem.id}`} className="btn btn-success" style={{
                              marginBottom:"10px"

              }}>
                احصائيات
              </Link>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">
                <Button
                  variant="danger"
                  onClick={() => {
                    const accept = async () => {
                      const response = await Apis.deleteService(serviceItem.id);
                      if (response.status === 'success') {
                        toast.success('تم حذف الخدمة بنجاح');
                        getServices();
                      } else {
                        toast.error('حدث خطأ, لم يتم حذف الخدمة');
                      }
                    };
          
                    const reject = () => {};
                    confirmDialog({
                      message: 'هل أنت متأكد انك تريد حذف هذا الخدمة؟',
                      header: 'تأكيد الحذف',
                      acceptClassName: 'p-button-danger',
                      rejectLabel: 'لا',
                      acceptLabel: 'نعم',
                      accept,
                      reject,
                    });
                  
                  }}                >
                  حذف
                </Button>
              </small>
            </Card.Footer>
          </Card>
              </Col>
         
          
           
          </>
        
        ))}
           </Row>
          </Container>
      </div>
    );
  };



  return (
    <div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>الخدمات</h1>

          <div>
            <Button onClick={() => setVisible(true)}>إضافة خدمة جديدة</Button>
          </div>
        </div>

        { renderCard()}

        <Dialog
          header="إضافة خدمة جديدة"
          visible={visible}
          style={{ width: '90vw', direction: 'rtl' }}
          onHide={() => setVisible(false)}
        >
        

          <p style={{ fontWeight: 'bold', marginTop: 20 }}>عنوان الخدمة</p>
          <InputText
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '100%' }}
          />

          <p style={{ fontWeight: 'bold', marginTop: 20 }}>الوصف</p>
          <InputTextarea
            value={textBody}
            onChange={(e) => setTextBody(e.target.value)}
            style={{ width: '100%' }}
          />

          <Button
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
            }}
            onClick={async () => {
              const response = await Apis.addService(title, textBody);
              if (response.status === 'success') {
                toast.success('تم إضافة الخدمة بنجاح');
                getServices();
                setVisible(false);
                setTitle("")
                setTextBody("")
              } else {
                toast.error('حدث خطأ, لم يتم إضافة الخدمة');
                getServices();
                setVisible(false);
                setTitle("")
                setTextBody("")
              }
            }}
          >
            إضافة
          </Button>
        </Dialog>
      </div>
    </div>
  );
};

export default Services;
