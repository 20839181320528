import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'primeicons/primeicons.css';

const Infos = () => {
  const [infosData, setInfosData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addImageURL, setAddImageUrl] = useState('');
  const [title, setTitle] = useState('');
  const [textBody, setTextBody] = useState('');

  const onTemplateUpload = (e) => {
    setAddImageUrl(JSON.parse(e.xhr.response).url);
  };

  const getInfos = async () => {
    try {
      const response = await Apis.getInfos();
      if (response.status === 'success') {
        console.log(response.data)
        setInfosData(response.data);
      }
    } catch (error) {
      console.error('Error fetching infos:', error);
    }
  };
  const formatDateTime = (dateString) => {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
    return formattedDate;
  };
  const formatDate = (dateString) => {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()}`;
    return formattedDate;
  };
  useEffect(() => {
    getInfos();
  }, []);

  const renderTable = () => {
    const columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.id}</div>
        ),
      },
      {
        id: 'title',
        header: 'العنوان',
        accessorKey: 'title',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.title}</div>
        ),
      },
      {
        id: 'description',
        header: 'الوصف',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const [showMore, setShowMore] = useState(false);

          const toggleShowMore = () => {
            setShowMore(!showMore);
          };

          return (
            <div className="text-end fw-bolder">
              <div
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: showMore ? 'unset' : 2,
                  lineHeight: '1.5em',
                }}
              >
                {row.original.description}
              </div>
              {row.original.description.length > 100 && (
                <a
                  onClick={toggleShowMore}
                  style={{
                    color: '#3A7BBF',
                    cursor: 'pointer',
                  }}
                >
                  {showMore ? 'تصغير' : 'عرض المزيد'}
                </a>
              )}
            </div>
          );
        },
      },
      {
        id: 'image',
        header: 'الصورة',
        accessorKey: 'image',
        Cell: ({ row }) => (
          <a href={row.original.image} target="_blank">
            <img
              src={row.original.image}
              alt={`Image ${row.original.id}`}
              style={{ width: 100, height: 100 }}
            />
          </a>
        ),
      },
      {
        id: 'delete',
        header: '',
        Cell: ({ row }) => {
          const accept = async () => {
            const response = await Apis.deleteInfo(row.original.id);
            if (response.status === 'success') {
              toast.success('تم حذف التقرير بنجاح');
              getInfos();
            } else {
              toast.error('حدث خطأ, لم يتم حذف التقرير');
            }
          };

          const reject = () => {};

          return (
            <Button
              variant="danger"
              onClick={() => {
                confirmDialog({
                  message: 'هل أنت متأكد انك تريد حذف هذا التقرير؟',
                  header: 'تأكيد الحذف',
                  acceptClassName: 'p-button-danger',
                  rejectLabel: 'لا',
                  acceptLabel: 'نعم',
                  accept,
                  reject,
                });
              }}
            >
              حذف
            </Button>
          );
        },
      },
      {
        id: 'files',
        header: '',
        Cell: ({ row }) => (
          <Button variant="primary">
            <Link
              to={`/infos/${row.original.id}`}
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
            >
              الملفات
            </Link>
          </Button>
        ),
      },
     /* {
        id: 'comments',
        header: '',
        Cell: ({ row }) => (
          <Button variant="primary">
            <Link
              to={`/infoscomments/${row.original.id}`}
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
            >
              التعليقات
            </Link>
          </Button>
        ),
      },*/
    ];

    return (
      <MaterialReactTable
        data={infosData}
        columns={columns}
        options={{
          search: false,
          paging: false,
        }}
      />
    );
  };

  const renderCard = () => {
    return (
      <div className="card-deck">
        {infosData.map((infoItem) => (
          <Card key={infoItem.id} style={{ marginBottom: '20px' }}>
            <Card.Img variant="top" src={infoItem.image} />
            <Card.Body>
              <Card.Title>{infoItem.title}</Card.Title>
              <Card.Text>{infoItem.bodytext}</Card.Text>
              <Card.Text>{formatDateTime(infoItem.startdate)}</Card.Text>
             {/* <Link to={`/infoscomments/${infoItem.id}`} className="btn btn-primary">
                التعليقات
        </Link>*/}
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">
                <Button
                  variant="danger"
                  onClick={() => {
                    const accept = async () => {
                      const response = await Apis.deleteInfo(infoItem.id);
                      if (response.status === 'success') {
                        toast.success('تم حذف التقرير بنجاح');
                        getInfos();
                      } else {
                        toast.error('حدث خطأ, لم يتم حذف التقرير');
                      }
                    };
          
                    const reject = () => {};
                    confirmDialog({
                      message: 'هل أنت متأكد انك تريد حذف هذه التقرير؟',
                      header: 'تأكيد الحذف',
                      acceptClassName: 'p-button-danger',
                      rejectLabel: 'لا',
                      acceptLabel: 'نعم',
                      accept,
                      reject,
                    });
                  
                  }}
                >
                  حذف
                </Button>
              </small>
            </Card.Footer>
          </Card>
        ))}
      </div>
    );
  };

  const handleDelete = async (id) => {
    const response = await Apis.deleteInfo(id);
    if (response.status === 'success') {
      toast.success('تم حذف التقرير بنجاح');
      getInfos();
    } else {
      toast.error('حدث خطأ, لم يتم حذف التقرير');
    }
  };

  return (
    <div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>تقارير ومنشورات</h1>

          <div>
            <Button onClick={() => setVisible(true)}>إضافة تقرير جديد</Button>
          </div>
        </div>

        {/* Render either table or card layout based on screen size */}
        { renderTable()}

        <Dialog
          header="إضافة تقرير"
          visible={visible}
          style={{ width: '90vw', direction: 'rtl' }}
          onHide={() => setVisible(false)}
        >
          {addImageURL && (
            <img
              src={addImageURL}
              style={{
                width: 200,
                height: 200,
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                marginBottom: 20,
                borderRadius: 20,
              }}
              alt="Added Image"
            />
          )}

          <FileUpload
            style={{
              direction: 'ltr',
              justifyContent: 'center',
              display: 'flex',
            }}
            mode="basic"
            name="file"
            url="https://api.ask4you.app/api/upload"
            accept="image/*"
            maxFileSize={1000000000}
            onUpload={onTemplateUpload}
            auto
            chooseLabel="صورة التقرير"
          />

          <p style={{ fontWeight: 'bold', marginTop: 20 }}>عنوان التقرير</p>
          <InputText
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '100%' }}
          />

<p style={{ fontWeight: 'bold', marginTop: 20 }}>نص التقرير</p>
          <InputTextarea
            value={textBody}
            onChange={(e) => setTextBody(e.target.value)}
            style={{ width: '100%' }}
          />

          <Button
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
            }}
            onClick={async () => {
              const response = await Apis.addInfo(title, textBody, addImageURL);
              console.log(response)
              if (response.status === 'success') {
                toast.success('تم إضافة التقرير بنجاح');
                getInfos();
                setVisible(false);
              } else {
                toast.error('حدث خطأ, لم يتم إضافة التقرير');
                getInfos();
                setVisible(false);
              }
            }}
          >
            إضافة
          </Button>
        </Dialog>
      </div>
    </div>
  );
};

export default Infos;
