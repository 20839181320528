import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import 'primeicons/primeicons.css';

const Faq = () => {
  const [faqData, setFaqData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [textBody, setTextBody] = useState('');


  const getFaq = async () => {
    try {
      const response = await Apis.getFaq();
      if (response.status === 'success') {
        console.log(response.data)
        setFaqData(response.data);
      }
    } catch (error) {
      console.error('Error fetching faq:', error);
    }
  };
  const formatDateTime = (dateString) => {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
    return formattedDate;
  };
  useEffect(() => {
    getFaq();
  }, []);

  const renderTable = () => {
    const columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.id}</div>
        ),
      },
      {
        id: 'question',
        header: 'السؤال',
        accessorKey: 'question',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.question}</div>
        ),
      },
      {
        id: 'answer',
        header: 'الجواب',
        accessorKey: 'answer',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.answer}</div>
        ),
      },
   
      {
        id: 'delete',
        header: '',
        Cell: ({ row }) => {
          const accept = async () => {
            const response = await Apis.deleteFaq(row.original.id);
            if (response.status === 'success') {
              toast.success(response.msg);
              getFaq();
            } else {
              toast.error('حدث خطأ, لم يتم حذف السؤال');
            }
          };

          const reject = () => {};

          return (
            <Button
              variant="danger"
              onClick={() => {
                confirmDialog({
                  message: 'هل أنت متأكد انك تريد حذف هذا السؤال؟',
                  header: 'تأكيد الحذف',
                  acceptClassName: 'p-button-danger',
                  rejectLabel: 'لا',
                  acceptLabel: 'نعم',
                  accept,
                  reject,
                });
              }}
            >
              حذف
            </Button>
          );
        },
      },
     /* {
        id: 'comments',
        header: '',
        Cell: ({ row }) => (
          <Button variant="primary">
            <Link
              to={`/faqcomments/${row.original.id}`}
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
            >
              التعليقات
            </Link>
          </Button>
        ),
      },*/
    ];

    return (
      <MaterialReactTable
        data={faqData}
        columns={columns}
        options={{
          search: false,
          paging: false,
        }}
      />
    );
  };



  const handleDelete = async (id) => {
    const response = await Apis.deleteEvent(id);
    if (response.status === 'success') {
      toast.success('تم حذف الفعالية بنجاح');
      getFaq();
    } else {
      toast.error('حدث خطأ, لم يتم حذف الفعالية');
    }
  };

  return (
    <div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>الاسئلة الشائعة</h1>

          <div>
            <Button onClick={() => setVisible(true)}>إضافة سؤال جديد</Button>
          </div>
        </div>

        {/* Render either table or card layout based on screen size */}
        {renderTable() }

        <Dialog
          header="إضافة سؤال جديد"
          visible={visible}
          style={{ width: '90vw', direction: 'rtl' }}
          onHide={() => setVisible(false)}
        >
          
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>السؤال</p>
          <InputText
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '100%' }}
          />

<p style={{ fontWeight: 'bold', marginTop: 20 }}>الجواب</p>
          <InputTextarea
            value={textBody}
            onChange={(e) => setTextBody(e.target.value)}
            style={{ width: '100%' }}
          />

          <Button
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
            }}
            onClick={async () => {
              const response = await Apis.addFaq(title, textBody);
              if (response.status === 'success') {
                toast.success('تم إضافة السؤال بنجاح');
                getFaq();
                setVisible(false);
              } else {
                toast.error('حدث خطأ, لم يتم إضافة السؤال');
                getFaq();
                setVisible(false);
              }
            }}
          >
            إضافة
          </Button>
        </Dialog>
      </div>
    </div>
  );
};

export default Faq;
