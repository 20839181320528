import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import Apis from './Apis';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import PollQuestionOptions from './PollQuestionOptions';
import { confirmDialog } from 'primereact/confirmdialog';

const EditPoll = () => {
  const [questions, setQuestions] = useState([]);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [questiontype, setQuestiontype] = useState('');
  const [pollTitle, setPollTitle] = useState('');
  const [pollDescription, setPollDescription] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  
  let { id } = useParams();

  useEffect(() => {
    getQuestions();
    getPoll();
    getPollStats();
  }, []);

  const getQuestions = async () => {
    const response = await Apis.getPollQuestions(id);
    if (response.status === 'success') {
      setQuestions(response.data);
    }
  };

  const getPollStats = async () => {
    const response = await Apis.getPollStats(id);
    if (response.status === 'success') {
      // Handle poll stats
    }
  };

  const getPoll = async () => {
    const response = await Apis.getPoll(id);
    if (response.status === 'success' && response.data.length > 0) {
      setPollTitle(response.data[0].title);
      setPollDescription(response.data[0].description);
    }
  };

  const handleAddQuestion = async () => {
    const response = await Apis.addQuestionToPoll(id, newQuestion, questiontype);
    if (response.status === 'success') {
      getQuestions();
      setDialogVisible(false);
      setNewQuestion('');
      setQuestiontype('');
    } else {
      toast.error('حدث خطأ، لم يتم إضافة السؤال');
    }
  };

  const handleEditQuestion = async () => {
    const response = await Apis.updatePollQuestion(questionToEdit.id, newQuestion, questiontype);
    if (response.status === 'success') {
      getQuestions();
      setDialogVisible(false);
      setEditMode(false);
      setQuestionToEdit(null);
      setNewQuestion('');
      setQuestiontype('');
    } else {
      toast.error('حدث خطأ، لم يتم تعديل السؤال');
    }
  };

  const openEditDialog = (question) => {
    setEditMode(true);
    setQuestionToEdit(question);
    setNewQuestion(question.question);
    setQuestiontype(question.questiontype);
    setDialogVisible(true);
  };

  return (
    <div style={{ direction: "rtl" }}>
      <p>{"استطلاع رقم : " + id}</p>
      <h3>{pollTitle}</h3>
      <h4 style={{ fontSize: "16px", marginBottom: "20px" }}>{pollDescription}</h4>

      {questions.map((question) => (
        <div key={question.id} style={{ border: "1px solid #00000077", padding: "20px", borderRadius: "5px", marginBottom: "20px" }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <h4 style={{ marginLeft: "30px" }}>{question.question}</h4>
            <a style={{ fontSize: "16px", color: "blue", cursor: "pointer", fontWeight: "bold", marginLeft: "10px" }} onClick={() => openEditDialog(question)}>
              تعديل
            </a>
            <a style={{ fontSize: "16px", color: "red", cursor: "pointer", fontWeight: "bold" }} onClick={() => {
              const accept = async () => {
                const response = await Apis.deletePollQuestion(question.id);
                if (response.status === 'success') {
                  toast.success('تم حذف السؤال بنجاح');
                  getQuestions();
                } else {
                  toast.error('حدث خطأ, لم يتم حذف السؤال');
                }
              };

              confirmDialog({
                message: 'هل أنت متأكد انك تريد حذف هذا السؤال؟',
                header: 'تأكيد الحذف',
                acceptClassName: 'p-button-danger',
                rejectLabel: 'لا',
                acceptLabel: 'نعم',
                accept,
                reject: () => {},
              });
            }}>
              حذف
            </a>
          </div>
          {question.questiontype === "اختيار من متعدد" && <PollQuestionOptions id={question.id} />}
        </div>
      ))}

      <a style={{ color: "#1976D2", fontWeight: "bold", cursor: "pointer" }} onClick={() => setDialogVisible(true)}>
        إضافة سؤال
      </a>

      <Dialog
        visible={isDialogVisible}
        style={{ direction: "rtl", width: "90%" }}
        onHide={() => {
          setDialogVisible(false);
          setEditMode(false);
          setQuestionToEdit(null);
        }}
        header={editMode ? "تعديل سؤال" : "إضافة سؤال"}
        footer={
          <div>
            <Button variant="primary" onClick={editMode ? handleEditQuestion : handleAddQuestion}>
              {editMode ? 'تعديل' : 'إضافة'}
            </Button>
            <Button variant="secondary" style={{ marginRight: "20px" }} onClick={() => setDialogVisible(false)}>
              إلغاء
            </Button>
          </div>
        }
      >
        <label style={{ width: isMobile ? "20%" : "10%" }}>السؤال: </label>
        <input type="text" style={{ width: isMobile ? "80%" : "90%" }} value={newQuestion} onChange={(e) => setNewQuestion(e.target.value)} />
        <label style={{ width: isMobile ? "20%" : "10%" }}>نوع السؤال: </label>
        <select style={{ width: isMobile ? "80%" : "90%" }} value={questiontype} onChange={(e) => setQuestiontype(e.target.value)}>
          <option value="اختيار من متعدد">اختيار من متعدد</option>
          <option value="نص">نص</option>
        </select>
      </Dialog>
    </div>
  );
};

export default EditPoll;
