// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, Button, CssBaseline } from '@mui/material';
import Home from './Home';
import News from './News';
import MiniDrawer from './Sidebar';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/primereact.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';  

import LoginPage from "./Auth/LoginPage";
import ResetPassword from "./Auth/ResetPassword";
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import NewsComments from './NewsComments';
import Events from './Events';
import EventsComments from './EventComments';
import Polls from './Polls';
import EditPoll from './EditPoll';
import PollStats from './PollStats';
import Services from './Services';
import EditService from './EditService';
import ServiceStats from './ServicesStats';
import "toastr/build/toastr.min.css";
import Volunteer from './Volunteer';
import Volunteers from './Volunteers';
import Complaints from './Complaints';
import Law from './Law';
import Faq from './Faq';
import Users from './Users';
import Municipalities from './Municipalities';
import Admins from './Admins';
import SuperAdminSidebar from './SuperAdminSidebar';
import Projects from './Projects';
import Infos from './Info';
import InfoFiles from './InfoFiles';
import InfoCenter from './InfoCenter';

function App() {


  return (
    <PrimeReactProvider>
  <Router>
      <CssBaseline />
      <Container style={{
        maxWidth:3000
      }}>
    
    <ToastContainer position="bottom-right"
    rtl={true}
    draggable
      
/>

  <ConfirmDialog/>


        <Routes>

        <Route path="/" element={<LoginPage />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/home" element={<SuperAdminSidebar Page={Municipalities}/>} />
      <Route path="/admins/:id" element={<SuperAdminSidebar Page={Admins}/>} />
        <Route path="/news" element={<MiniDrawer Page={News}/>} />
        <Route path="/newscomments/:id" element={<MiniDrawer Page={NewsComments}/>} />
        <Route path="/events" element={<MiniDrawer Page={Events}/>} />
        <Route path="/projects" element={<MiniDrawer Page={Projects}/>} />
        <Route path="/infos" element={<MiniDrawer Page={Infos}/>} />
        <Route path="/infos/:id" element={<MiniDrawer Page={InfoFiles}/>} />
        <Route path="/eventscomments/:id" element={<MiniDrawer Page={EventsComments}/>} />
        <Route path="/polls" element={<MiniDrawer Page={Polls}/>} />
        <Route path="/polledit/:id" element={<MiniDrawer Page={EditPoll}/>} />
        <Route path="/pollstats/:id" element={<MiniDrawer Page={PollStats}/>} />
        <Route path="/services" element={<MiniDrawer Page={Services}/>} />
        <Route path="/serviceedit/:id" element={<MiniDrawer Page={EditService}/>} />
        <Route path="/servicestats/:id" element={<MiniDrawer Page={ServiceStats}/>} />
        <Route path="/volunteer" element={<MiniDrawer Page={Volunteer}/>} />
        <Route path="/volunteers/:id" element={<MiniDrawer Page={Volunteers}/>} />
        <Route path="/complaints" element={<MiniDrawer Page={Complaints}/>} />
        <Route path="/law" element={<MiniDrawer Page={Law}/>} />
        <Route path="/faq" element={<MiniDrawer Page={Faq}/>} />
        <Route path="/users" element={<MiniDrawer Page={Users}/>} />
        <Route path="/infocenter" element={<MiniDrawer Page={InfoCenter}/>} />


          </Routes>
   
      </Container>
    </Router>
    </PrimeReactProvider>
  
  );
}

export default App;
