import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import axios from "axios";
import user from "../image/user (3).png";
import lock from "../image/lock.png";
import avatar from "../image/password.png";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import "toastr/build/toastr.min.css";

import Apis from "../Apis";


const LoginPage = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(email&&password){
         setIsLoading(true);
    try {
      const response = await Apis.adminLogin(email,password)
      console.log(response)

      const { status, msg, token,role} = response;

      if (status === "success") {
        toast.success("تم تسجيل الدخول  بنجاح");
        if(role=="super admin")
        localStorage.setItem("superadmintoken", token);
      else
      localStorage.setItem("token", token);

        localStorage.setItem("role", role);
        navigate("/home");
      } else {
        toast.error(msg, "خطأ");
      }
    } catch (error) {
      toast.error("كلمة المرور او البريد الكتروني غير صحيح", "خطأ");
    }
    setIsLoading(false);
    }
 else{
  toast.error("جميع الحقول اجبارية","خطأ")
 }
  };


  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if(email){
         setIsLoading(true);
    try {
    /*  const response = await axios.post(
        "https://whatsapp.hakini.net:3002/api/forgotPassword",
        {
          email,
          
        }
      );

      const { status, msg} = response.data;

      if (status === "success") {
        toastr.success(msg);
      } else {
        toastr.error(msg, "خطأ");
      }*/
    } catch (error) {
      console.log(error)
      toast.error("حدث خطأ , الرجاء المحاولة لاحقاً", "خطأ");
    }
    setIsLoading(false);
    }
 else{
  toast.error("الرجاء ادخال البريد الالكتروني","خطأ")
 }
  };



  return (
    <Container className="mt-5 ">
      <div
        className=" border   m-auto border-2 shadow-lg rounded-3  text-end  "
        style={{ maxWidth: "550px" }}
      >
        <div className=" align-items-center d-grid justify-content-center">
          <h4 className="mt-5 text-center"> تسجيل الدخول</h4>

          <img width="160px" className="ms-2  mt-5  " src={avatar} />
        </div>

        <Form className="mt-5   p-3" onSubmit={handleSubmit}>
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Label className="">اسم المستخدم</Form.Label>
            <div className="d-flex">
              <img className="ms-2 p-1" src={user} />
              <Form.Control
                className="shadow-lg"
                type="text"
                placeholder="ادخل اسم  المستخدم"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="formBasicPassword"  style={{
            marginBottom:"20px"
          }}>
            <Form.Label>كلمة المرور</Form.Label>
            <div className="d-flex ">
              <img className="ms-2 p-1" src={lock} />
              <Form.Control
                className="shadow-lg"
                type="password"
                placeholder="ادخل كلمة المرور"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </Form.Group>
       
          <Button
            className=" rounded-3 shadow-lg w-100 mt-5"
            variant="primary"
            type="submit"
            disabled={isLoading}
              
          >
            تسجيل دخول
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default LoginPage;
