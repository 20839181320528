import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import 'primeicons/primeicons.css';

const Volunteer = () => {
  const [volunteerData, setVolunteerData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addImageURL, setAddImageUrl] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [field, setField] = useState('');
const [dateandtime,setDateAndTime]=useState(dayjs(new Date(Date.now())))
  const getVolunteer = async () => {
    try {
      const response = await Apis.getVolunteer();
      if (response.status === 'success') {
        setVolunteerData(response.data);
      }
    } catch (error) {
      console.error('Error fetching volunteer:', error);
    }
  };

  useEffect(() => {
    getVolunteer();
  }, []);

  const onTemplateUpload = (e) => {
    setAddImageUrl(JSON.parse(e.xhr.response).url);
  };
  const formatDateTime = (dateString) => {
    const date = new Date( dateString);
    const formattedDate = `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()} ${("0" + date.getHours()).slice(-2)}:${(
      "0" + date.getMinutes()
    ).slice(-2)}`;
    return formattedDate;
  };
  const renderTable = () => {
    const columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.id}</div>
        ),
      },
      {
        id: 'title',
        header: 'العنوان',
        accessorKey: 'title',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.title}</div>
        ),
      },
      {
        id: 'description',
        header: 'وصف',
        accessorKey: 'description',
        Cell: ({ row }) => (
       
          <div className="text-end fw-bolder">{  row.original.description}</div>

    
    
    
        ),
      },
      {
        id: 'field',
        header: 'مجال التطوع',
        accessorKey: 'field',
        Cell: ({ row }) => (
       
          <div className="text-end fw-bolder">{  row.original.field}</div>

    
    
    
        ),
      },
      {
        id: 'image',
        header: 'الصورة',
        accessorKey: 'image',
        Cell: ({ row }) => (
          <a href={row.original.image} target="_blank">
            <img
              src={row.original.image}
              alt={`Image ${row.original.id}`}
              style={{ width: 100, height: 100 }}
            />
          </a>
        ),
      },
      {
        id: 'delete',
        header: '',
        Cell: ({ row }) => {
          const accept = async () => {
            const response = await Apis.deleteVolunteerData(row.original.id);
            if (response.status === 'success') {
              toast.success('تم حذف الفعالية بنجاح');
              getVolunteer();
            } else {
              toast.error('حدث خطأ, لم يتم حذف الفعالية');
            }
          };

          const reject = () => {};

          return (
            <Button
              variant="danger"
              onClick={() => {
                confirmDialog({
                  message: 'هل أنت متأكد انك تريد حذف هذه الفعالية؟ ',
                  header: 'تأكيد الحذف',
                  acceptClassName: 'p-button-danger',
                  rejectLabel: 'لا',
                  acceptLabel: 'نعم',
                  accept,
                  reject,
                });
              }}
            >
              حذف
            </Button>
          );
        },
      },
      {
        id: 'Volunteers',
        header: '',
        Cell: ({ row }) => (
          <Button variant="primary">
            <Link
              to={`/volunteers/${row.original.id}`}
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
            >
              المتطوعين
            </Link>
          </Button>
        ),
      },
    ];

    return (
      <MaterialReactTable
        data={volunteerData}
        columns={columns}
        options={{
          search: false,
          paging: false,
        }}
      />
    );
  };

  const renderCard = () => {
    return (
      <div className="card-deck">
        {volunteerData.map((volunteerItem) => (
          <Card key={volunteerItem.id} style={{ marginBottom: '20px' }}>
            <Card.Img variant="top" src={volunteerItem.image} />
            <Card.Body>
              <Card.Title>{volunteerItem.title}</Card.Title>
              <Card.Text>{ formatDateTime(volunteerItem.dateandtime)}</Card.Text>
              <Link to={`/volunteers/${volunteerItem.id}`} className="btn btn-primary">
                المتطوعين
              </Link>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">
                <Button
                  variant="danger"
                  onClick={() => handleDelete(volunteerItem.id)}
                >
                  حذف
                </Button>
              </small>
            </Card.Footer>
          </Card>
        ))}
      </div>
    );
  };

  const handleDelete = async (id) => {
    const response = await Apis.deleteVolunteerData(id);
    if (response.status === 'success') {
      toast.success('تم حذف الفعالية بنجاح');
      getVolunteer();
    } else {
      toast.error('حدث خطأ, لم يتم حذف الفعالية');
    }
  };

  return (


    <LocalizationProvider dateAdapter={AdapterDayjs}>

<div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>فعاليات التطوع</h1>
  
          <div>
            <Button onClick={() => setVisible(true)}>إضافة فعالية جديدة</Button>
          </div>
        </div>
  
        {/* Render either table or card layout based on screen size */}
        { renderTable()}
  
        <Dialog
          header="إضافة فعالية"
          visible={visible}
          style={{ width: '90vw', direction: 'rtl' }}
          onHide={() => setVisible(false)}
        >
          {addImageURL && (
            <img
              src={addImageURL}
              style={{
                width: 200,
                height: 200,
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                marginBottom: 20,
                borderRadius: 20,
              }}
              alt="Added Image"
            />
          )}
  
          <FileUpload
            style={{
              direction: 'ltr',
              justifyContent: 'center',
              display: 'flex',
            }}
            mode="basic"
            name="file"
            url="https://api.ask4you.app/api/upload"
            accept="image/*"
            maxFileSize={1000000000}
            onUpload={onTemplateUpload}
            auto
            chooseLabel="صورة الفعالية"
          />
  
  <p style={{ fontWeight: 'bold', marginTop: 20 }}>عنوان الفعالية</p>
          <InputText
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '100%' }}
          />
                 <p style={{ fontWeight: 'bold', marginTop: 20 }}>وصف الفعالية</p>
          <InputText
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ width: '100%' }}
          />
                 <p style={{ fontWeight: 'bold', marginTop: 20 }}>مجال الفعالية</p>
          <InputText
            value={field}
            onChange={(e) => setField(e.target.value)}
            style={{ width: '100%' }}
          />
  
       
  
          <Button
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              marginTop:20
            }}
            onClick={async () => {
              const response = await Apis.addVolunteerData(
                title,
               description,
               field,
                addImageURL
              );
              if (response.status === 'success') {
                toast.success('تم إضافة الفعالية بنجاح');
                getVolunteer();
                setVisible(false);
              } else {
                toast.error('حدث خطأ, لم يتم إضافة الفعالية');
                getVolunteer();
                setVisible(false);
              }
            }}
          >
            إضافة
          </Button>
        </Dialog>
      </div>
    </div>


  </LocalizationProvider>


    
  );
  
};

export default Volunteer;
