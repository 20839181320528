import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import Apis from './Apis'; // Assume Apis.updatePollQuestionOption is defined somewhere in your code.
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { isMobile } from 'react-device-detect';

const PollQuestionOptions = ({ id }) => {
    const [options, setOptions] = useState([]);
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [newOption, setNewOption] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [optionToEdit, setOptionToEdit] = useState(null);

    useEffect(() => {
        getOptions();
    }, []);

    const getOptions = async () => {
        const response = await Apis.getPollQuestionOptions(id);
        if (response.status === 'success') {
            setOptions(response.data);
        }
    };

    const handleAddOption = async () => {
        const response = await Apis.addOptionToPollQuestion(id, newOption);
        if (response.status === 'success') {
            getOptions();
            setDialogVisible(false);
            setNewOption('');
        } else {
            toast.error('حدث خطأ، لم يتم إضافة الخيار');
        }
    };

    const handleEditOption = async () => {
        const response = await Apis.updatePollQuestionOption(optionToEdit.id, newOption);
        if (response.status === 'success') {
            getOptions();
            setDialogVisible(false);
            setNewOption('');
            setEditMode(false);
            setOptionToEdit(null);
        } else {
            toast.error('حدث خطأ، لم يتم تعديل الخيار');
        }
    };

    const openEditDialog = (option) => {
        setEditMode(true);
        setOptionToEdit(option);
        setNewOption(option.optiontext);
        setDialogVisible(true);
    };

    return (
        <div style={{ direction: 'rtl' }}>
            {options.map((option, index) => (
                <div key={option.id} style={{ display: "flex", flexDirection: "row" }}>
                    <input type="radio" name="pollOption" value={option.optiontext} disabled />
                    <label style={{ marginRight: "10px", marginLeft: "30px", marginBottom: "15px" }}>
                        {option.optiontext}
                    </label>
                    <a style={{ fontSize: "16px", color: "blue", cursor: "pointer", fontWeight: "bold",marginLeft:"20px" }} 
                        onClick={() => openEditDialog(option)}>
                        تعديل
                    </a>
                    <a style={{ fontSize: "16px", color: "red", cursor: "pointer", fontWeight: "bold", marginLeft: "10px" }} 
                        onClick={() => {
                            const accept = async () => {
                                const response = await Apis.deletePollQuestionOption(option.id);
                                if (response.status === 'success') {
                                    toast.success('تم حذف الخيار بنجاح');
                                    getOptions();
                                } else {
                                    toast.error('حدث خطأ, لم يتم حذف الخيار');
                                }
                            };
                            const reject = () => {};
                            confirmDialog({
                                message: 'هل أنت متأكد انك تريد حذف هذا الخيار؟',
                                header: 'تأكيد الحذف',
                                acceptClassName: 'p-button-danger',
                                rejectLabel: 'لا',
                                acceptLabel: 'نعم',
                                accept,
                                reject,
                            });
                        }}>
                        حذف
                    </a>
                </div>
            ))}

            { (
                <a style={{ color: "#1976D2", fontWeight: "bold", cursor: "pointer" }} onClick={() => setDialogVisible(true)}>
                    إضافة خيار
                </a>
            )}

            <Dialog
                visible={isDialogVisible}
                style={{ direction: "rtl", width: "90%" }}
                onHide={() => {
                    setDialogVisible(false);
                    setEditMode(false);
                    setOptionToEdit(null);
                }}
                header={editMode ? "تعديل خيار" : "إضافة خيار"}
                footer={
                    <div>
                        <Button variant="primary" onClick={editMode ? handleEditOption : handleAddOption}>
                            {editMode ? 'تعديل' : 'اضافة'}
                        </Button>
                        <Button variant="secondary" style={{ marginRight: "20px" }} onClick={() => setDialogVisible(false)}>
                            إلغاء
                        </Button>
                    </div>
                }
            >
                <label style={{ width: isMobile ? "20%" : "10%" }}>الخيار: </label>
                <input
                    type="text"
                    style={{ width: isMobile ? "80%" : "90%" }}
                    value={newOption}
                    onChange={(e) => setNewOption(e.target.value)}
                />
            </Dialog>
        </div>
    );
};

export default PollQuestionOptions;
