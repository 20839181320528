import React, { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import user from "../image/user (3).png";
import lock from "../image/lock.png";
import avatar from "../image/password.png";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";


const ResetPassword = () => {

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(password&&repeatPassword&&password==repeatPassword){
      if(password.length<8)
      {

        toastr.error('Error','Please enter a password with at least 8 characters');
      }
      else
      {

      
      const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');
         setIsLoading(true);
         const response = await fetch("https://whatsapp.hakini.net:3002/api/resetPassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            password
          }),
        });
    
        if (response) {
          
          
          toastr.success('Success', 'Your Password has been reset!');
          navigate("/");

        }
    setIsLoading(false);
    }
  }
 else{
  toastr.error("كلمة المرور لا تطابق التأكيد","خطأ")
 }

  };




  return (
    <Container className="mt-5 ">
      <div
        className=" border   m-auto border-2 shadow-lg rounded-3  text-end  "
        style={{ maxWidth: "550px" }}
      >
        <div className=" align-items-center d-grid justify-content-center">
          <h4 className="mt-5 text-center">تغيير كلمة المرور</h4>

          <img width="160px" className="ms-2  mt-5  " src={avatar} />
        </div>

        <Form className="mt-5   p-3" onSubmit={handleSubmit}>
          <Form.Group className="mb-4" controlId="formBasicEmail">
            <Form.Label className="">كلمة المرور</Form.Label>
            <div className="d-flex">
              <img className="ms-2 p-1" src={user} />
              <Form.Control
                className="shadow-lg"
                type="password"
                placeholder="********"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="formBasicPassword"  style={{
            marginBottom:"20px"
          }}>
            <Form.Label>تأكيد كلمة المرور</Form.Label>
            <div className="d-flex ">
              <img className="ms-2 p-1" src={lock} />
              <Form.Control
                className="shadow-lg"
                type="password"
                placeholder="********"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
            </div>
          </Form.Group>
        
          <Button
            className=" rounded-3 shadow-lg w-100 mt-5"
            variant="primary"
            type="submit"
            disabled={isLoading}
              
          >
            تغيير كلمة المرور
          </Button>
        </Form>
      </div>
    </Container>
  );
};

export default ResetPassword;
