import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Home from './Home';
import { isMobile } from 'react-device-detect';
import { ElectricalServices, Event, Poll } from '@mui/icons-material';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import GavelIcon from '@mui/icons-material/Gavel';
import QuizIcon from '@mui/icons-material/Quiz';
import GroupIcon from '@mui/icons-material/Group';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ConstructionIcon from '@mui/icons-material/Construction';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginRight: drawerWidth, // swapped marginLeft with marginRight
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer({Page}) {
  const theme = useTheme();
  theme.direction = "rtl"; // added this line
  const [open, setOpen] = React.useState(isMobile?false:true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex',flexDirection:"row-reverse" }}>
      <AppBar position="fixed" open={open} dir='rtl'>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginLeft: 5, // swapped marginRight with marginLeft
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
          نظام ادارة البلديات
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} anchor='right'>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{
            direction:"rtl"
        }}>
           <Link to={"/news"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"الاخبار"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <NewspaperIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"الاخبار"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/events"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"الفعاليات"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <Event /> 
                                </ListItemIcon>
                <ListItemText primary={"الفعاليات"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/polls"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"استطلاعات الرأي"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <Poll /> 
                                </ListItemIcon>
                <ListItemText primary={"استطلاعات الرأي"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/services"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"الخدمات"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <ElectricalServices /> 
                                </ListItemIcon>
                <ListItemText primary={"الخدمات"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/projects"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"المشاريع"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <ConstructionIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"المشاريع"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/infos"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"تقارير ومنشورات"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <HelpOutlineIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"تقارير ومنشورات"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/infocenter"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"مركز المعلومات"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <HelpOutlineIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"مركز المعلومات"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/volunteer"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"التطوع"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <VolunteerActivismIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"التطوع"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/complaints"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"الشكاوى"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <AssistantPhotoIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"الشكاوى"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/law"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"المساءلة المجتمعية"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <GavelIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"المساءلة المجتمعية"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/faq"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"الاسئلة الشائعة"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <QuizIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"الاسئلة الشائعة"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/users"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"المستخدمين"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <GroupIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"المستخدمين"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>

            <Button style={{
              marginLeft:"auto",
              marginRight:"auto",
              textAlign:"center",
              display:"block",
              color:"red"
            }} onClick={async()=>{

               localStorage.removeItem("token")
              navigate("/")

            }}>
              تسجيل الخروج
            </Button>
        </List>
        <Divider />
    
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      <Page/>
      </Box>
    </Box>
  );
}
